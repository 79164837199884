<template>
  <Csidebar v-if="check_service_codeg">
  </Csidebar>
  <CSidebar 
    v-else
    fixed 
    :minimize="minimize"
    :show="show"
    size="sm"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none">
      <div v-if="check_service_srds">
        <a href="#/srds">
          <img src="@/assets/icons/logo3.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <div v-else-if="check_service_btfs">
        <a href="#/btfs">
          <img src="@/assets/icons/btfs_logo.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <div v-else-if="check_service_pwdm">
        <a href="#/pwdm">
          <img src="@/assets/icons/logo3.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>
      <div v-else-if="check_service_thcs">
        <a href="#/thcs">
          <img src="@/assets/icons/btfs_logo.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>      
      <div v-else-if="check_service_watek">
        <a href="#/watek">
          <img src="@/assets/icons/watek_logo.jpg" style="height:56px;width:192px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
      </div>      
      <div v-else-if="check_service_fumax ">
        <img src="@/assets/icons/fumax_logo.png" style="height:56px;width:195px;" class="c-sidebar-brand-full"/>
        <!--<a href="#/fumax">
          <img src="@/assets/icons/fumax_logo.png" style="height:56px;width:195px;" class="c-sidebar-brand-full"/>
        </a>-->
      </div>
      <div v-else>    
        <a href="#/dashboard">
          <img src="@/assets/icons/logo3.png" style="height:56px;width:195px;object-fit:cover;" class="c-sidebar-brand-full"/>
        </a>
        <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      </div>
      <!-- <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>

    <!-- <CSidebarNavItem>  
        <CLink
        class="c-sidebar-nav-link"   
        to="/dashboard"
        :exact="true"
        activeClassName="c-active"
      >
        <FontAwesomeIcon class="c-sidebar-nav-icon" icon="coffee" />
        Test02
      </CLink>
    </CSidebarNavItem>  -->

    <div v-if="check_service_srds">
      <CRenderFunction flat :content-to-render="$options.nav_srds"/>
    </div>
    <div v-else-if="check_service_military">
      <CRenderFunction flat :content-to-render="$options.nav_military"/>
    </div>
    <div v-else>
      <CRenderFunction flat :content-to-render="$options.nav"/>
    </div>
    <!-- <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    /> -->
  </CSidebar>

</template>

<script>
import loopback from "@/services/loopback";
import nav from './_nav'
import nav_srds from './_nav_srds'
import nav_military from './_nav_military'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'TheSidebar',
  nav,
  nav_srds,
  nav_military,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    check_service_srds () {
      return this.user.service_type == 'srds';
    },
    check_service_btfs () {
      return this.user.service_type == 'btfs';
    },
    check_service_pwdm () {
      return this.user.service_type == 'pwdm';
    },
    check_service_codeg () {
      return this.user.service_type == 'codeg';
    },
    check_service_thcs () {
      return this.user.service_type == 'thcs';
    },
    check_service_watek () {
      return this.user.service_type == 'watek';
    },
    check_service_military () {
      return this.user.service_type == 'military';
    },
    check_service_fumax () {
      return this.user.service_type =='fumax' || this.user.service_type =='snow';
    }
  },
  created: function() {
    this.user_info = this.$store.state.auth.user_info;
    this.user = this.userInfo = this.user_info.user
  },
  data () {
    var item_name = '관리자 메뉴';
    if (this.$store.state.auth.is_admin === true &&
        _.findIndex(nav[0]._children, {name: item_name}) === -1 ) {
      var manage_item = {
        _name: 'CSidebarNavDropdown',
        name: item_name,
        icon: 'cil-task',
        items: [
          {
            name: '장비 현황',
            to: '/manage',
            badge: {
              color: 'info',
              text: 'NEW'
            }            
          },
          {
            name: '로그인 기록',
            to: '/loginhistory',
            badge: {
              color: 'info',
              text: 'NEW'
            }            
          },
          {
            name: 'Portainer',
            href: loopback.portainer(),
            target: '_blank',
          },
          {
            name: 'Dozzle',
            href: loopback.portainer() + '/logs',
            target: '_blank',
          },
          {
            name: 'Explorer',
            href: loopback.explorer(),
            target: '_blank',
          },
          {
            name: 'MongoExp',
            href: loopback.mongoexp(),
            target: '_blank',
          },
          {
            name: 'RabbitMQ',
            href: loopback.rabbitmq(),
            target: '_blank',
          }
        ]
      }
      nav[0]._children.push(manage_item);
      nav_srds[0]._children.push(manage_item);
    } else if (this.$store.state.auth.is_admin === false) {
      _.remove(nav[0]._children, {name: item_name});
      _.remove(nav_srds[0]._children, {name: item_name});

      _.remove(nav[0]._children, {name: '이벤트 관리'});
      _.remove(nav_srds[0]._children, {name: '상황 발생 이력 조회'});

      _.remove(nav[0]._children, {name: '사이트 관리'});
      _.remove(nav_srds[0]._children, {name: '데이터수집기 관리'});      
    }
    return {
      is_admin: this.$store.state.auth.is_admin
    }
  }
}
</script>
